/**
 * Search Field in Masthead
 * Optional part of Global Navigation. Includes conditional initialization of autosuggest,
 * and recent search history.
 */

import searchInit from '../search';
import suggestInit from '../suggest';
import recentSearchInit from '../recents';
import getScriptData from '../../../util/getScriptData';

const {
  enableAutosuggest = false,
  enableRecentSearch = false,
  showSearch = false,
  searchAction = '',
  useConstructorAutosuggest = false,
} = getScriptData('gnav-data') || {};

export function app(win) {
  if (showSearch) {
    /* init the search bar (expand / contract) */
    searchInit({
      masthead: '[data-js=masthead]',
      searchForm: '[data-js=search-form]',
      searchField: '[data-js=search-field]',
      clearButton: '[data-js=clear-button]',
      searchButton: '[data-js=search-button]',
      searchCancel: '[data-js=search-cancel]',
    });
  }
  if (enableAutosuggest && !(useConstructorAutosuggest && searchAction === '/search')) {
    /* init listboxes - autosuggest & recent search history */
    suggestInit({
      searchField: '[data-js=search-field]',
      suggestionBox: '[data-js=suggestion-box]',
      suggestionList: '[data-js=suggestion-list]',
      searchForm: '[data-js=search-form]',
    });
  }
  if (enableRecentSearch) {
    recentSearchInit({
      recentHistoryBox: '[data-js=recent-search-box]',
      searchForm: '[data-js=search-form]',
    });
  }

  return win;
}

export function main(win = window) {
  if (win.document.readyState === 'loading') {
    // Loading hasn't finished yet
    win.document.addEventListener('DOMContentLoaded', app.bind(null, win));
  } else {
    // `DOMContentLoaded` has already fired
    app(win);
  }
}
